/* .main-div{
    width: 100%;
    height: 100%;
    background-color: rgb(69 74 84 / 65%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}


.container-div{
    min-width: 360px;
    padding: 2rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border-radius: 4px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.cancel-btnn{
    position: absolute;
    right: 10px;
    top: 10px;
    border: 0;
    color: #03569c;
    background-color: transparent;
    cursor: pointer;
}

.formstyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.formstyle .center-div{
    padding: 20px 0;
}

.center-div > p {
    font-size: 1.5em;
    font-weight: bold;
}

.inputestyle{
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.center-div .buttonn{
    padding: 10px 30px;
    background-color: rgb(3, 86, 156);
    color: white;
    border-radius: 30px;
    font-size: 16px;
    border: none;
}
.center-div .buttonn:hover{
  background-color: #3d80b6;
  border: 1px solid #03569c;
  color: white;
  cursor: pointer;
} */




.otpmodal {
    width: 100%;
    height: 100%;
    background-color: rgb(69 74 84 / 65%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .cancel-btnn{
    position: absolute;
    right: 10px;
    top: 10px;
    border: 0;
    color: #03569c;
    background-color: transparent;
    cursor: pointer;
}

  .main-div{
    min-width: 360px;
    padding: 2rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border-radius: 4px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .center-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .center-div .buttonn{
      padding: 10px 30px;
      background-color: rgb(3, 86, 156);
      color: white;
      border-radius: 30px;
      font-size: 16px;
      border: none;
  }

  .center-div .buttonn:hover{
    background-color: #3d80b6;
    border: 1px solid #03569c;
    color: white;
    cursor: pointer;
  }