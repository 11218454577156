
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Segoe UI";

}

.modal-Background{
    width: 100%;
    height: 100%;
    background-color: rgb(69 74 84 / 65%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
}

.modal-Background .review-modal{
    width: 500px;
    height: 400px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    border-radius: 30px;
    z-index: 1;
    padding: 0;
}

.modal-Background .track-order{
    width: 500px;
    height: 300px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    border-radius: 30px;
    z-index: 1;
    padding: 0;
}
.track-order .cancel-btnn, .review-modal .cancel-btnn{
    position: absolute;
    right: 15px;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    top: 25px;
}

.track-order  .track, .review-modal .track{
    padding: 20px;
    margin: 0px 0 30px 0;
    background-color: #772F22;
    color: white;
    border-radius: 30px 30px 0 0;
    font-size: 1vw;
    text-align: left;
}

.track-order input, .review-modal input{
    height: 50px;
    padding-left: 20px;
    margin: 0px 15px 15px 15px;
    background-color: white;
    border: 1px solid #772F22;
    border-radius: 30px;
    text-align: left;
    outline: 0;   
}
.track-order  input:focus, .review-modal input:focus, .your-review:focus {
    box-shadow: 0 0 5px 0 #965b50;
    background-color: white;
}
.track-order .submit-btn, .review-modal .submit-btn{
    height: 50px;
    background-color: #772F22;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    color: white;
    margin: 0px 15px 10px 15px;
}

.track-order .order-status, .review-modal .order-status{
    margin: 20px;
    font-size: 14px;
    font-weight: bold;
}

.review-modal .your-review{
    height: 50px;
    padding: 10px 0 0 20px;
    margin: 0px 15px 0px 15px;
    background-color: white;
    border: 1px solid #772F22;
    border-radius: 5px;
    text-align: left;
    outline: 0; 
}

.review-modal .your-rating{
    padding:  20px 0;
    margin: 0px 15px 0 15px;
    text-align: left;
    font-size: 14px;
   color:  #6e6f70;
}

