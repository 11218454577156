* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main_cart {
    width: 300px;
    background-color: #f1f3f4;
    box-shadow: 0px 0px 0px 2px #e4e7ed;
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
}

.main_cart .display_products {
    width: 100%;
}

.display_products .viewcart-product {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    width: 100%;
}

.display_products .viewcart-product .product-sec1 {
    width: 20%;
}

.display_products .viewcart-product .cartimage{
    height: 56px;
    width: 100%;
    text-align: left;
    display: flex;
    border-radius: 5px;
}

.display_products .viewcart-product .descrepation-sec {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5px;
    justify-content: center;
    align-items: center;

}

.display_products .viewcart-product .descrepation-sec .product-sec2 {
    width: 100%;
}

.display_products .viewcart-product .descrepation-sec .product-sec2 p {
    font-size: 12px;
    text-transform: capitalize;
    font-family: "Segoe UI";
    text-align: left;
    color: #39c;
}



.display_products .viewcart-product .descrepation-sec .product-sec4 {
    width: 34%;
}

.display_products .viewcart-product .descrepation-sec .product-sec4 input {
    width: 40px;
    height: 15px;
    outline: none;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 5px;
}


.display_products .viewcart-product .descrepation-sec .product-sec5 {
    width: 33%;
    text-align: center;
}

.display_products .viewcart-product .descrepation-sec .product-sec5 p {
    font-size: 14px;
    text-align: center;
    font-weight: 0;
}

.display_products .viewcart-product .descrepation-sec .product-sec6 {
    width: 33%;
    text-align: center;
    padding: 0 7px 0 7px;
    margin: 0;
}

.display_products .viewcart-product .descrepation-sec .product-sec6 p {
    width: 100%;
    font-size: 14px;
    color: red;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.main_cart .des_sabtotal {
    border-top: 1px solid #E4E7ED;
    padding: 10px;

}

.main_cart .des_sabtotal .show_lengthitems {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

.main_cart .des_sabtotal p {
    font-size: 14px;
}

.main_cart .des_sabtotal .sabtotal_mini_cart {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.des_sabtotal .sabtotal_mini_cart .sabtotal_text {
    width: 50%;
    text-align: left;
}

.des_sabtotal .sabtotal_mini_cart .sabtotal_rs {
    width: 50%;
    text-align: right;


}


.main_cart .cart_battns {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.cart_battns .cart_battns1 {
    width: 50%;
    padding: 5px;

}

.cart_battns .cart_battns1 button {

    background-color: #D10024;
    color: white;
    text-align: center;
    width: 100%;
    height: 35px;
    cursor: pointer;
    border-radius: 28px;
    border-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart_battns .cart_battns1 button:hover {
    background-color: #df5055;
}